import React, {lazy, memo, Suspense} from 'react';
import cache from '@ohoareau/cache';
import {useQuery} from "@apollo/react-hooks";
import * as locales from "@material-ui/core/locale";

export const getScreen = (name, fallback = <div />) => cache.getset('screens', name, () => {
    const path = name.includes('/') ? name : `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    const Component = lazy(() => import(`./components/screens/${path}Screen.jsx`));
    return memo(props => <Suspense fallback={fallback}><Component {...props} /></Suspense>);
});

export const prepareErrors = (errors, section = undefined) => {
    section = section || '_';
    return (errors || []).reduce((acc, e) => {
        if (!e.errorInfo) return acc;
        if (!e.errorInfo[section] || 0 === Object.keys(e.errorInfo[section]).length) return acc;
        acc.push(...e.errorInfo[section].map(e => e));
        return acc;
    }, []);
};

export const configize = c => {
    const routePathRoles = (c.routes || []).reduce((acc, r) => Object.assign(acc, {[r.path || '*']: !r.role ? [] : (Array.isArray(r.role) ? r.role : [r.role])}), {})
    return {...c, routePathRoles, hasFeature: k => c && c.features && c.features[k]};
}

export const isUserHavingRole = (user, role) =>
    !role.length ? true : (!!role.find(x => (user.permissions || []).includes(x)))
;
export const createItemFilter = (user, routePathRoles = []) => item =>
    isUserHavingRole(user, routePathRoles[item.path || '*'] || [])
;

export const useRoleQuery = (user, map, defaultOptions = {fetchPolicy: 'cache-and-network'}) => {
    const role = Object.keys(map).find(k => user.permissions.includes(k));
    const [query, options = {}] = Array.isArray(map[role]) ? map[role] : [map[role]];
    return {...useQuery(query, {...defaultOptions, ...options}), role};
};

export const langToMuiLocale = lang => {
    const defaultLocale = 'enUS';

    // Exceptions à ajouter en fonction des langues disponibles dans l'interface
    const conversions = { en: 'enUS' };

    const locale = conversions.hasOwnProperty(lang) ? conversions[lang] : `${lang}${lang.toUpperCase()}`;

    return locales[locale] ? locales[locale] : locales[defaultLocale];
}

export const timestampToLocaleStringOrEmptyString = (ts) => {
    const date = new Date(parseInt(ts));
    return date instanceof Date && date.toLocaleString() !== 'Invalid Date' ? date.toLocaleString() : '';
}