export default [
    {id: 'home', path: '/', icon: 'home'},
    {id: 'users', path: '/users', icon: 'users'},
    {id: 'organizations', path: '/organizations', icon: 'organizations'},
    {id: 'platforms', path: '/platforms', icon: 'platforms'},
    {id: 'tokens', path: '/tokens', icon: 'tokens'},
    {id: 'operations', path: '/operations', icon: 'operations'},
    {id: 'jobs', path: '/jobs', icon: 'jobs'},
    {id: 'tasks', path: '/tasks', icon: 'tasks'},
    {id: 'help', path: '/help', icon: 'help'},
]