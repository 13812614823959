export default {
    app_name: 'Admin',
    app_main_title: 'Computo',
    home_welcome: 'Bienvenue sur Computo Admin',
    help_welcome: "Pour avoir de l'aide dans l'utilisation de Computo Admin",
    login_form_title: 'Connexion',
    login_form_description: 'Merci de saisir vos identifiants pour vous connecter',
    login_form_action_label: 'Connexion',
    new_password_form_title: 'Changement de mot de passe requis',
    new_password_form_description: 'Votre précédent mot de passe a expiré, merci de saisir un nouveau mot de passe pour vous connecter.',
    password_reset_form_title: 'Changement de mot de passe requis',
    password_reset_form_description: 'Une demande de changement de mot de passe a été initié pour votre compte, vous avez dû recevoir, par email, un code de sécurité à saisir ci-dessous en plus de votre nouveau mot de passe.',
    constraints_required: 'obligatoire',
    error_validation: 'Merci de vérifier votre formulaire, un ou plusieurs champs ne sont pas valides',
    error_generic: "Une erreur inattendue est survenue, merci de contacter l'administrateur (erreur: {{message}})",
    error_forbidden: 'Identifiants non reconnus ou accès non autorisé',
    errors_unknown_query: "Requêtes GraphQL '{{query}}' inconnue (disponible: {{queryNames}})",
    role_user_label: 'Utilisateur',
    role_admin_label: 'Administrateur',
    buttons_notavailable_label: 'Non disponible',
    buttons_create_label: 'Créer',
    buttons_new_label: 'Ajouter',
    buttons_add_label: 'Créer',
    buttons_edit_label: 'Modifier',
    buttons_save_label: 'Enregistrer',
    buttons_delete_label: 'Supprimer',
    buttons_execute_label: 'Executer',
    buttons_display_label: 'Afficher',
    buttons_archive_label: 'Archiver',
    buttons_reopen_label: 'Ré-ouvrir',
    buttons_login_label: 'Connexion',
    buttons_cancel_label: 'Annuler',
    buttons_submit_label: 'Soumettre',
    buttons_change_label: 'Modifier',
    buttons_next_label: 'Suivant',
    buttons_back_label: 'Précédent',
    buttons_reset_label: 'Annuler',
    buttons_finish_label: 'Valider',
    buttons_settings_label: 'Paramètres',
    buttons_moveup_label: 'Monter',
    buttons_movedown_label: 'Descendre',
    buttons_duplicate_label: 'Dupliquer',
    buttons_collapseall_label: 'Tout replier',
    buttons_expandall_label: 'Tout déplier',
    buttons_import_label: 'Importer',
    buttons_menu_label: 'Menu',
    buttons_clone_label: 'Dupliquer',
    buttons_lock_label: 'Verrouiller',
    buttons_unlock_label: 'Déverrouiller',
    buttons_get_help_label: 'Contacter oha@greenberets.io',
    buttons_get_help_label_short: 'Contacter',
    errors_auth_generic: 'Erreur de type {{type}} lors de l\'authentification',
    errors_auth_bad_credentials: 'Mot de passe incorrect pour {{username}}',
    errors_auth_unknown_user: 'Aucun compte utilisateur pour {{username}}',
    errors_auth_empty_user_context_for_new_password_completion: 'Votre requête de changement de mot de passe a expiré, merci de ré-itérer votre demande',
    errors_auth_empty_username_context_for_password_reset_completion: 'Votre requête de remise à zéro de votre mot de passe a expiré, merci de ré-itérer votre demande',
    errors_auth_missing_attributes_for_new_password_completion: 'Votre requête de changement de mot de passe a expiré, la confirmation n\'est plus possible, merci de ré-itérer votre demande',
    errors_auth_new_password_required: 'Vous devez changer le mot de passe pour {{username}}',
    errors_auth_password_policy_restriction: 'Le mot de passe saisi ne respecte pas les contraintes imposées par la politique de restriction pour les mots de passe imposée pour {{username}}{{description}}',
    errors_auth_password_reset_required: 'Une remise à zéro du mot de passe est nécessaire pour {{username}}',
    errors_auth_code_mismatch: 'Le code sécurité transmis n\'est pas le bon',
    errors_auth_limit_exceeded: 'Le nombre de tentative erronée maximum a été atteint, merci de contacter votre administrateur',
    errors_missing_groups: 'Vous ne disposez pas des autorisations nécessaires pour vous connecter sur cette application (vous n\'êtes pas {{missingGroups}})',
    page_not_found_title: 'Page introuvable',
    page_denied_title: 'Accès non autorisé',
    page_unauthorized_title: 'Accès non autorisé',
    layouts_standard_draw_open_label: 'Ouvrir le volet',
    usermenu_logout_label: 'Déconnexion',
    usermenu_profile_label: 'Mon compte',
    languagemenu_en_label: 'English',
    languagemenu_fr_label: 'Français',
    loading_title: 'Chargement en cours...',
    page_not_found: 'Page introuvable :(',
    page_denied: 'Accès non autorisé.',
    dropzone_input_content: "Glissez/déposez votre fichier ici ou cliquez",
    dropzone_input_content_multiple: "Glissez/déposez vos fichiers ici ou cliquez",
    dropzone_input_content_with_files: "Ajouter des fichiers",
    dropzone_submit_button_content: "Envoyer",
    constraints_count_not_a_number: "doit être un nombre",
    constraints_count_not_positive: "doit être supérieur à 0",
    constraints_count_too_high: "doit être inférieur à {{max}}",
    constraints_count_not_integer: "doit être un nombre entier",
    constraints_email: "email invalide",
    constraints_maxlength: "{{max}} caractères maximum",
    constraints_number: "doit être un nombre",
    constraints_password: "mot de passe non valide (8 caractères minimum, des chiffres, un caractère spécial, des majuscules, des minuscules)",
    constraints_security_code: "code de sécurité non valide (6 chiffres requis)",
    constraints_zip_code: "code postal non valide (5 chiffres requis)",
    constraints_phone: "numéro de téléphone invalide (+331... ou 01.... ou (+33)1....)",
    constraints_fax: "numéro de fax invalide (+331... ou 01.... ou (+33)1....)",
    constraints_mobilePhone: "numéro de téléphone mobile invalide (+336... ou 06.... ou (+33)6....)",
    constraints_url: "adresse internet non valide (doit commencer par http ou https)",
    constraints_arn: "ARN de Lambda AWS invalide",
    constraints_role_arn: "ARN de Rôle AWS invalide",
    constraints_maxitems: "maximum {{max}} {{itemTypePluralName}}",
    constraints_json: "erreur de syntaxe JSON",
    item_type_tag: 'tag',
    item_type_tag_plural: 'tags',
    field_tags_label: "Tags",
    field_type_label: "Type",
    field_content_type_label: "Format",
    field_title_label: "Titre",
    field_language_label: "Langue",
    field_categories_label: "Catégories",
    field_role_label: "Rôle",
    field_description_label: "Description",
    field_file_label: "Fichier",
    field_code_label: "Code",
    field_nature_label: "Nature",
    field_status_label: "Statut",
    field_cedex_label: "Cedex",
    field_cedex_placeholder: "Ex: Cedex 15",
    field_city_label: "Ville",
    field_city_placeholder: "Ex: Colombel",
    field_complement_label: "Complément",
    field_complement_placeholder: "",
    field_count_label: "Nombre",
    field_count_placeholder: "Ex: 3",
    field_country_label: "Pays",
    field_country_placeholder: "Ex: France",
    field_date_label: "Date",
    field_date_placeholder: "",
    field_email_label: "Email",
    field_email_placeholder: "marie.dupont@monentreprise.fr",
    field_firstname_label: "Prénom",
    field_firstname_placeholder: "Marie",
    field_lastname_label: "Nom de famille",
    field_lastname_placeholder: "Dupont",
    field_token_label: "Jeton",
    field_token_placeholder: "Jeton",
    field_name_label: "Nom",
    field_name_placeholder: "Nom",
    field_number_label: "Nombre",
    field_number_placeholder: "Ex: 1",
    field_password_label: "Mot de passe",
    field_password_placeholder: "Votre mot de passe personnel ici",
    field_phone_label: "Téléphone",
    field_phone_placeholder: "Ex: 0198765432",
    field_mobilePhone_label: "Téléphone Mobile",
    field_mobilePhone_placeholder: "Ex: 0612345678",
    field_securitycode_label: "Code de sécurité",
    field_securitycode_placeholder: "123456",
    field_reference_label: "Référence",
    field_reference_placeholder: "Ex: XC-123-A",
    field_siren_label: "SIREN",
    field_siren_placeholder: "Ex: 509703625",
    field_siret_label: "SIRET",
    field_siret_placeholder: "Ex: 50970362500020",
    field_street_label: "Adresse",
    field_street_placeholder: "Ex: 147, rue d'en Haut",
    field_text_label: "Titre",
    field_text_placeholder: "",
    field_username_label: "Email",
    field_username_placeholder: "marie.dupont@monentreprise.fr",
    field_zipcode_label: "Code postal",
    field_zipcode_placeholder: "Ex: 58350",
    field_linkedIn_label: "LinkedIn",
    field_website_label: "Site internet",
    field_url_label: "Adresse internet",
    field_comment_label: "Commentaire",
    field_job_id_label: "JobId",
    field_job_type_label: "Type de job",
    field_job_definition_label: "Définition du job",
    field_task_type_label: "Type de tâche",
    field_taks_definition_label: "Définition de la tâche",
    field_linkedIn_placeholder: "Ex: https://linkedin.com/...",
    field_website_placeholder: "Ex: https://www.monentreprise.fr/...",
    field_comment_placeholder: "Ex: ...",
    field_organization_label: "Organisation",
    field_organization_placeholder: "Mon Entreprise",
    field_webhook_label: "Web Hook",
    field_webhook_placeholder: "https://maplateforme.fr/mon-webhook",
    field_platform_label: "Plateforme",
    field_platform_placeholder: "Ma Plateforme",
    field_divideresource_label: "DIVIDE : Resource de découpage en sous-étapes",
    field_divideresource_placeholder: "arn ou https",
    field_computeresource_label: "COMPUTE : Resource de calcul d'une sous-étape",
    field_computeresource_placeholder: "arn ou https",
    field_reduceresource_label: "REDUCE : Resource d'aggrégation des résultats",
    field_reduceresource_placeholder: "arn ou https",
    field_globalassumerole_label: "Rôle à assumer globalement",
    field_globalassumerole_placeholder: "arn ou https",
    field_divideassumerole_label: "Rôle à assumer pour la resource de découpage en sous-étapes (DIVIDE)",
    field_divideassumerole_placeholder: "arn ou https",
    field_computeassumerole_label: "Rôle à assumer pour la resource de calcul d'une sous-étapes (COMPUTE)",
    field_computeassumerole_placeholder: "arn ou https",
    field_reduceassumerole_label: "Rôle à assumer pour la resource d'aggrégation des résultats (REDUCE)",
    field_reduceeassumerole_placeholder: "arn ou https",
    field_operation_label: "Opération",
    field_task_definition_label: "Définition",
    field_createdAt_label: "Date de création",
    field_job_results_label: "Résultats",
    field_job_results_placeholder: "Résultats",
    field_job_orders_label: "Ordres",
    field_job_orders_placeholder: "Ordres",
    field_job_error_label: "Erreur",
    field_job_error_placeholder: "Erreur",
    menu_items_home: 'Tableau de Bord',
    menu_items_users: 'Utilisateurs',
    menu_items_organizations: 'Organisations',
    menu_items_platforms: 'Plateformes',
    menu_items_tokens: 'Jetons',
    menu_items_jobs: 'Jobs',
    menu_items_tasks: 'Tâches',
    menu_items_operations: 'Opérations',
    menu_items_help: "Besoin d'aide ?",
    tabs_generic_empty_text: 'Aucun élément',
    tabs_infojob_label: 'Infos',
    tabs_definitionjob_label: 'Définition',
    tabs_ordersjob_label: 'Ordres',
    tabs_resultsjob_label: 'Résultats',
    tabs_errorjob_label: 'Erreur',
    user_list_column_lastname_label: 'Nom',
    confirm_delete_title: "Êtes-vous sûr ?",
    confirm_delete_description: "Cette action est permanente !",
    confirm_delete_confirmation: "Valider",
    confirm_delete_cancellation: "Annuler",
    FAILED: "Échoué",
    CREATED: "Créé",
    RUNNING: "En cours...",
    COMPLETED: "Terminé"
}