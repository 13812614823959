import {configize} from '../utils';
import menuitems from './menuitems';
import routes from './routes';
import themes from './themes';
import translations from './translations';

export default configize({
    persistKey: 'computo_superadmin',
    requiredRole: ['admin'],
    theme: 'theme1',
    defaultLanguage: 'fr',
    features: {
        localeSwitcher: true,
    },
    menuitems,
    routes,
    themes,
    translations,
})