import app from './app';
import user from './user';
import organization from "./organization";
import platform from "./platform";
import token from "./token";
import job from "./job";
import task from "./task";
import operation from "./operation";

export default [
    ...user,
    ...organization,
    ...platform,
    ...token,
    ...job,
    ...task,
    ...operation,
    ...app,
]
