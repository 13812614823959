export default {
    app_name: 'Admin',
    app_main_title: 'Computo',
    home_welcome: 'Welcome on Computo Admin',
    help_welcome: 'How to gelp help on using Computo Admin',
    login_form_title: 'Login',
    login_form_description: 'Please fill in your credentials to login',
    login_form_action_label: 'Login',
    new_password_form_title: 'Password Change required',
    new_password_form_description: 'Your previous password has expired, please provide a new password to login',
    password_reset_form_title: 'Password Reset Confirmation',
    password_reset_form_description: 'You are someone has requested a password reset on this account. Please fill in the security code received on your device and the new password',
    constraints_required: 'required',
    error_validation: 'Please check your form, some fields are not valid',
    error_generic: 'There was an unexpected error, please contact your administrator (error: {{message}})',
    error_forbidden: 'Bad credentials and/or forbidden',
    errors_unknown_query: "Unknown GraphQL query '{{query}}' (available: {{queryNames}})",
    role_user_label: 'User',
    role_admin_label: 'Administrator',
    buttons_new_label: 'Add',
    buttons_add_label: 'Create',
    buttons_create_label: 'Create',
    buttons_edit_label: 'Edit',
    buttons_save_label: 'Save',
    buttons_delete_label: 'Delete',
    buttons_execute_label: 'Execute',
    buttons_display_label: 'Display',
    buttons_login_label: 'Login',
    buttons_cancel_label: 'Cancel',
    buttons_submit_label: 'Submit',
    buttons_change_label: 'Change',
    buttons_next_label: 'Next',
    buttons_back_label: 'Previous',
    buttons_reset_label: 'Cancel',
    buttons_finish_label: 'Finish',
    buttons_settings_label: 'Settings',
    buttons_moveup_label: 'Move up',
    buttons_movedown_label: 'Move down',
    buttons_duplicate_label: 'Clone',
    buttons_collapseall_label: 'Collapse all',
    buttons_expandall_label: 'Expand all',
    buttons_import_label: 'Import',
    buttons_menu_label: 'Menu',
    buttons_clone_label: 'Clone',
    buttons_lock_label: 'Lock',
    buttons_unlock_label: 'Unlock',
    buttons_get_help_label: 'Contact oha@greenberets.io',
    buttons_get_help_label_short: 'Contact',
    errors_auth_generic: 'Auth {{type}} error',
    errors_auth_bad_credentials: 'Bad credentials for user {{username}}',
    errors_auth_unknown_user: 'Unknown user {{username}}',
    errors_auth_empty_user_context_for_new_password_completion: 'Empty user context for new password completion',
    errors_auth_empty_username_context_for_password_reset_completion: 'Empty username context for password reset completion',
    errors_auth_missing_attributes_for_new_password_completion: 'Missing attributes {{attributes}} for new password completion of user {{username}}',
    errors_auth_new_password_required: 'New password required for user {{username}}',
    errors_auth_password_policy_restriction: 'Provided password does not comply with password policy restriction for user {{username}}{{description}}',
    errors_auth_password_reset_required: 'Password reset is required for {{username}}',
    errors_auth_code_mismatch: 'Invalid security code',
    errors_auth_limit_exceeded: 'Maximum number of failed attempts reached, please contact your administrator',
    errors_missing_groups: 'You are not allowed to access this application (missings {{missingGroups}} rights)',
    page_not_found_title: 'Not Found :(',
    page_denied_title: 'Denied Access',
    page_unauthorized_title: 'Unauthorized Access',
    layouts_standard_draw_open_label: 'Open drawer',
    usermenu_logout_label: 'Logout',
    usermenu_profile_label: 'My Profile',
    languagemenu_en_label: 'English',
    languagemenu_fr_label: 'Français',
    constraints_count_not_a_number: "must be a number",
    constraints_count_not_positive: "must be greater than 0",
    constraints_count_too_high: "must be less than {{max}}",
    constraints_count_not_integer: "must be a whole number",
    constraints_email: "invalid email address",
    constraints_maxlength: "must be {{max}} characters or less",
    constraints_number: "must be a number",
    constraints_password: "invalid password (min 8 characters, digits, special characters, upper case characters, lower case characters)",
    constraints_security_code: "invalid security code (6 digits required)",
    constraints_zip_code: "invalid zip code (5 digits required)",
    field_tags_label: "Tags",
    field_type_label: "Type",
    field_title_label: "Title",
    field_language_label: "Language",
    field_categories_label: "Categories",
    field_role_label: "Role",
    field_description_label: "Description",
    field_code_label: "Code",
    field_nature_label: "Nature",
    field_status_label: "Status",
    field_cedex_label: "Cedex",
    field_cedex_placeholder: "Ex: Cedex 15",
    field_city_label: "City",
    field_city_placeholder: "Ex: Colombel",
    field_complement_label: "Complement",
    field_complement_placeholder: "",
    field_count_label: "Count",
    field_count_placeholder: "Ex: 3",
    field_country_label: "Country",
    field_country_placeholder: "Ex: France",
    field_date_label: "Date",
    field_date_placeholder: "",
    field_email_label: "Email",
    field_email_placeholder: "mary.smith@mycompany.com",
    field_firstname_label: "First Name",
    field_firstname_placeholder: "Mary",
    field_lastname_label: "Last Name",
    field_lastname_placeholder: "Smith",
    field_token_label: "Token",
    field_token_placeholder: "Token",
    field_name_label: "Name",
    field_name_placeholder: "Name",
    field_number_label: "Number",
    field_number_placeholder: "Ex: 1",
    field_password_label: "Password",
    field_password_placeholder: "Your personal password here",
    field_phone_label: "Phone",
    field_phone_placeholder: "Ex: 0612345678",
    field_reference_label: "Reference",
    field_reference_placeholder: "Ex: XC-123-A",
    field_securitycode_label: "Security Code",
    field_securitycode_placeholder: "123456",
    field_siren_label: "SIREN",
    field_siren_placeholder: "Ex: 509703625",
    field_siret_label: "SIRET",
    field_siret_placeholder: "Ex: 50970362500020",
    field_vat_label: "N° TVA Intra",
    field_vat_placeholder: "Ex: FR74509703625",
    field_street_label: "Address",
    field_street_placeholder: "Ex: 147, rue d'en Haut",
    field_text_label: "Text",
    field_text_placeholder: "",
    field_username_label: "Email",
    field_username_placeholder: "mary.smith@mycompany.com",
    field_zipcode_label: "Zip code",
    field_zipcode_placeholder: "Ex: 58350",
    field_url_label: "URL",
    field_operation_label: "Operation",
    field_task_definition_label: "Definition",
    menu_items_home: 'Dashboard',
    menu_items_users: 'Users',
    menu_items_organizations: 'Organizations',
    menu_items_platforms: 'Platforms',
    menu_items_tokens: 'Tokens',
    menu_items_jobs: 'Jobs',
    menu_items_tasks: 'Tasks',
    menu_items_operations: 'Operations',
    menu_items_help: 'Need help ?',
    confirm_delete_title: 'Are you sure?',
    confirm_delete_description: 'This action is permanent!',
    confirm_delete_confirmation: "OK",
    confirm_delete_cancellation: "Cancel",
}