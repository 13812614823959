import i18n from 'i18next';
import config from './configs';
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import {createMuiTheme} from '@material-ui/core/styles';
import {reducer as form} from 'redux-form';
import {initReactI18next} from 'react-i18next';
import {createBrowserHistory} from 'history';
import {persistStore, persistReducer} from 'redux-persist'
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createClient, reducerFactory as userReducerFactory, onBeforeLiftCreator} from '@ohoareau/react-plugin-user';
import {combineReducers, applyMiddleware, compose, createStore} from 'redux'
import {langToMuiLocale} from "./utils";

i18n
    .use(initReactI18next)
    .init({
        resources: Object.entries(config.translations || {}).reduce((acc, [k, v]) => Object.assign(acc, {[k]: {translation: v}}), {}),
        lng: config.defaultLanguage,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        }
    })
    .then()
;

export {default as i18n} from 'i18next';
export const history = createBrowserHistory();
export const store = createStore(
    persistReducer(
        {key: config.persistKey, storage},
        combineReducers({router: connectRouter(history), user: userReducerFactory({requiredRole: config.requiredRole || []}), form})
    ),
    undefined,
    ((typeof window !== 'undefined' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']) || compose)(
        applyMiddleware(
            routerMiddleware(history),
            thunkMiddleware,
        ),
    )
);

export const persistor = persistStore(store);
export const muiTheme = createMuiTheme(config.themes[config.theme], langToMuiLocale(config.defaultLanguage));
export const client = createClient({store, uri: process.env.REACT_APP_API_ENDPOINT});
export const onBeforeLift = onBeforeLiftCreator({store});